



















































































































































































































































































































































































































































import Vue from "vue";
export default Vue.extend({
  name: "Testimonios",
  components: {},
  data: () => ({}),
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  props: {
    merchant: {},
  },
  methods: {},
});
